<template>
    <div class="register-box">
        <div class="register-logo">
            <img src="@/assets/logo.svg" alt="ANASAP Logo">
        </div>
        <div class="register-box-body">
            <h1 class="login-box-msg">Registrace</h1>
            <form novalidate>
                <div class="form-group has-feedback" :class="{'has-error': $v.company_name.$error}">
                    <input v-model.lazy="$v.company_name.$model" type="text" class="form-control" placeholder="Jméno společnosti nebo osoby">
                    <span class="glyphicon glyphicon-tower form-control-feedback"></span>
                    <span v-if="!$v.company_name.maxLength" class="help-block">Jméno společnosti může mít maximálně {{$v.company_name.$params.maxLength.max}} znaků!</span>
                </div>
                <div class="form-group has-feedback" :class="{'has-error': $v.ico.$error}">
                    <input v-model.lazy="$v.ico.$model" type="text" class="form-control" placeholder="IČO">
                    <span class="glyphicon glyphicon-tag form-control-feedback"></span>
                    <span v-if="!$v.ico.minLength" class="help-block">IČO musí mít minimálně {{$v.ico.$params.minLength.min}} znaků!</span>
                    <span v-if="!$v.ico.maxLength" class="help-block">IČO může mít maximálně {{$v.ico.$params.maxLength.max}} znaků!</span>
                </div>
                <div class="form-group has-feedback" :class="{'has-error': $v.email.$error || this.emailUsed}">
                    <input v-model.lazy="$v.email.$model" type="email" class="form-control" placeholder="Email">
                    <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                    <span v-if="!$v.email.email" class="help-block">E-mailová adresa musí mít formát emailu!</span>
                    <span v-if="!$v.email.maxLength" class="help-block">E-mailová adresa může mít maximálně {{$v.email.$params.maxLength.max}} znaků!</span>
                    <span v-if="this.emailUsed" class="help-block">Účet s touto e-mailovou adresou již existuje!</span>
                </div>
                <div class="form-group has-feedback" :class="{'has-error': $v.password.$error}">
                    <input v-model.lazy="$v.password.$model" type="password" class="form-control" placeholder="Heslo">
                    <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                    <span v-if="!$v.password.minLength" class="help-block">Heslo musí mít minimálně {{$v.password.$params.minLength.min}} znaků!</span>
                    <span v-if="!$v.password.maxLength" class="help-block">Heslo může mít maximálně {{$v.password.$params.maxLength.max}} znaků!</span>
                </div>
                <div class="form-group has-feedback" :class="{'has-error': $v.password_check.$error}">
                    <input v-model.lazy="$v.password_check.$model" type="password" class="form-control" placeholder="Heslo znovu">
                    <span class="glyphicon glyphicon-log-in form-control-feedback"></span>
                    <span v-if="!$v.password_check.sameAsPassword && $v.password_check.$error" class="help-block">Heslo musí být stejné jako heslo zadané výše!</span>
                </div>
                <div v-if="this.error" class="form-group has-feedback has-error">
                    <span class="help-block">Došlo k chybě při odesílání formuláře, prosím obnovte stránku. Pokud problém setrvá, kontakujte prosím podporu <a href="mailto:gabriela.chumchalova@anafra.cz">sales@anasap.cz</a></span>
                </div>
                <div class="register-button-container">
                    <div class="text-center"><a href="/#/login" class="anasap-link">Již mám účet</a></div>
                    <div><button type="button" @click="submitForm" class="ml-auto anasap-btn" :disabled="$v.$invalid || this.error">Registrovat</button></div>
                </div>
            </form>  
        </div>
    </div>
</template>

<script>
const name = 'Register';
import api from '../store/classes/api'; 

// Vuelidate
import { required,  maxLength, email, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    name,
    data() {
        return {
            error: false,
            emailSent: undefined,
            company_name: '',
            ico: '',
            email: '',
            password: '',
            password_check: ''
        }
    },
    validations: {
        company_name: {
            required,
            maxLength: maxLength(32),
        },
        ico: {
            required, 
            minLength: minLength(7),
            maxLength: maxLength(9)
        },
        email: {
            required,
            email,
            maxLength: maxLength(32)
        },
        password: {
            required,
            minLength: minLength(6),
            maxLength: maxLength(32)
        },
        password_check: {
            required,
            sameAsPassword: sameAs('password'),
        }
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            if (this.$v.$invalid)
            {
                return 1;
            }

            try
            {
                const response = await api.register(this.company_name, this.email, this.password, this.ico);

                if (response === undefined)
                {
                    throw {code: "EXCEPTION", message: "An exception occured."};
                }
 
                if (response.code == "VERIFY_LINK_SENT")
                {
                    this.$router.push({name: 'SentVerificationEmail'});
                }
            }
            catch (error)
            {
                switch (error.code)
                {
                    case "EMAIL_INUSE":
                        this.error = false;
                        this.emailSent = this.email;
                        break;
                    default:
                        this.error = true;
                        break;
                }
            }  
        },
    },
    computed: {
        emailUsed() {
            return (this.email == this.emailSent);
        },
        console: () => console
    }
}
</script>

<style>
    @import "../assets/scss/login.scss";
</style>
